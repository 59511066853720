import { createRouter, createWebHistory } from "vue-router";
import Layout from "@/components/Layout.vue";
import UserTokenStorage from "@/storages/userTokenStorage";
import { isAdmin, isEmployee, isApprover, isSuperApprover } from "@/helpers/userRoleHelper";

const adminRouteRule = (to, from, next) => {
  if (isAdmin()) {
    next();
  } else {
    next({
      path: "/404",
    });
  }
};

const adminAndApproverRouteRule = (to, from, next) => {
  if (isAdmin() || isApprover() || isSuperApprover()) {
    next();
  } else {
    next({
      path: "/404",
    });
  }
};

const employeeRouteRule = (to, from, next) => {
  if (!isEmployee()) {
    return next({ path: "/404" });
  }
  return next();
};

const routes = [
  {
    path: "/404",
    component: () => import("@/views/error_pages/404.vue"),
    hidden: true,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    hidden: true,
  },
  // Admin Routes
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    beforeEnter: (to, from, next) => {
      if (isAdmin() || isEmployee()) {
        next();
      } else {
        next({
          path: "/my_leaves",
        });
      }
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
    ],
  },
  {
    path: "/leaves",
    component: Layout,
    beforeEnter: adminAndApproverRouteRule,
    children: [
      {
        path: "/leaves",
        component: () => import("@/views/leave/Leave.vue"),
        name: "Leaves",
      },
    ],
  },
  {
    path: "/employees",
    component: Layout,
    beforeEnter: adminAndApproverRouteRule,
    children: [
      {
        path: "/employees",
        component: () => import("@/views/employee/Employee.vue"),
        name: "Employees",
      },
    ],
  },
  {
    path: "/top_leave_request",
    component: Layout,
    beforeEnter: adminAndApproverRouteRule,
    children: [
      {
        path: "/top_leave_request",
        component: () => import("@/views/top_leave_request/TopLeaveRequest.vue"),
        name: "TopLeaveRequest",
      },
    ],
  },
  {
    path: "/employee_resignations",
    component: Layout,
    beforeEnter: adminAndApproverRouteRule,
    children: [
      {
        path: "/employee_resignations",
        component: () => import("@/views/employee_resignations/EmployeeResignation.vue"),
        name: "Employee Resignations",
      },
    ],
  },
  {
    path: "/employee_attendances",
    component: Layout,
    beforeEnter: adminAndApproverRouteRule,
    children: [
      {
        path: "/employee_attendances",
        component: () => import("@/views/employee_attendances/EmployeeAttendance.vue"),
        name: "Employee Attendances",
      },
    ],
  },
  {
    path: "/departments",
    beforeEnter: adminRouteRule,
    component: Layout,
    children: [
      {
        path: "/departments",
        component: () => import("@/views/department/Department.vue"),
        name: "Departments",
      },
    ],
  },
  {
    path: "/branches",
    beforeEnter: adminRouteRule,
    component: Layout,
    children: [
      {
        path: "/branches",
        component: () => import("@/views/branch/Branch.vue"),
        name: "Branches",
      },
    ],
  },
  {
    path: "/leave_types",
    component: Layout,
    beforeEnter: adminRouteRule,
    children: [
      {
        path: "/leave_types",
        component: () => import("@/views/leave_type/LeaveType.vue"),
        name: "LeaveTypes",
      },
    ],
  },
  {
    path: "/users",
    beforeEnter: adminRouteRule,
    component: Layout,
    children: [
      {
        path: "/users",
        component: () => import("@/views/user/User.vue"),
        name: "Users",
      },
    ],
  },
  {
    path: "/profile",
    component: Layout,
    children: [
      {
        path: "/profile",
        component: () => import("@/views/profile/Profile.vue"),
        name: "Profile",
      },
    ],
  },
  {
    path: "/employee_leave_balance/:staffId",
    component: Layout,
    beforeEnter: adminAndApproverRouteRule,
    children: [
      {
        path: "/employee_leave_balance/:staffId",
        component: () =>
          import("@/views/employee_leave_balance/EmployeeLeaveBalance.vue"),
        name: "EmployeeLeaveBalance",
      },
    ],
  },
  {
    path: "/employees_leave_balance/",
    component: Layout,
    beforeEnter: adminAndApproverRouteRule,
    children: [
      {
        path: "/employees_leave_balance/",
        component: () =>
          import("@/views/employees_leave_balance/EmployeesLeaveBalance.vue"),
        name: "EmployeesLeaveBalance",
      },
    ],
  },
  {
    path: "/relationships",
    beforeEnter: adminRouteRule,
    component: Layout,
    children: [
      {
        path: "/relationships",
        component: () => import("@/views/relationship/Relationship.vue"),
        name: "Relationships",
      },
    ],
  },
  {
    path: "/nationalities",
    beforeEnter: adminRouteRule,
    component: Layout,
    children: [
      {
        path: "/nationalities",
        component: () => import("@/views/nationality/Nationality.vue"),
        name: "Nationalities",
      },
    ],
  },
  {
    path: "/schedules",
    beforeEnter: adminRouteRule,
    component: Layout,
    children: [
      {
        path: "/schedules",
        component: () => import("@/views/schedule/Schedule.vue"),
        name: "Schedules",
      },
    ],
  },
  {
    path: "/org_chart",
    beforeEnter: adminRouteRule,
    component: Layout,
    children: [
      {
        path: "/org_chart",
        component: () => import("@/views/org_chart/OrgChart.vue"),
        name: "OrgChart",
      },
    ],
  },
  {
    path: "/attendance_reports",
    beforeEnter: adminRouteRule,
    component: Layout,
    children: [
      {
        path: "/attendance_reports",
        component: () => import("@/views/attendance_report/AttendanceReport.vue"),
        name: "Attendance Report",
      },
    ],
  },
  {
    path: "/leave_histories/:staffId",
    beforeEnter: adminRouteRule,
    component: Layout,
    children: [
      {
        path: "/leave_histories/:staffId",
        component: () => import("@/views/employee_leave_history/EmployeeLeaveHistory.vue"),
        name: "EmployeeLeaveHistory",
      },
    ],
  },
  {
    path: "/employment_histories/:staffId",
    beforeEnter: adminRouteRule,
    component: Layout,
    children: [
      {
        path: "/employment_histories/:staffId",
        component: () => import("@/views/employment_history/EmploymentHistory.vue"),
        name: "EmploymentHistory",
      },
    ],
  },
  {
    path: "/employment_activity_log/:id",
    beforeEnter: adminRouteRule,
    component: Layout,
    children: [
      {
        path: "/employment_activity_log/:id",
        component: () => import("@/views/employment_activity_log/EmploymentActivityLog.vue"),
        name: "EmploymentActivityLog",
      },
    ],
  },
  {
    path: "/preview_employee_schedule/:id",
    beforeEnter: adminRouteRule,
    component: Layout,
    children: [
      {
        path: "/preview_employee_schedule/:id",
        component: () => import("@/views/preview_employee_schedule/PreviewEmployeeSchedule.vue"),
        name: "Preview Employee Schedule",
      },
    ],
  },
  // {
  //   path: "/settings/",
  //   beforeEnter: adminRouteRule,
  //   component: Layout,
  //   children: [
  //     {
  //       path: "/settings/",
  //       component: () => import("@/views/setting/Setting.vue"),
  //       name: "Setting",
  //     },
  //   ],
  // },
  // Employee Routes
  {
    path: "/check_in_out",
    component: Layout,
    beforeEnter: employeeRouteRule,
    children: [
      {
        path: "/check_in_out",
        component: () => import("@/views/check_in_out/CheckInOut.vue"),
        name: "Check In/Out",
      },
    ],
  },
  {
    path: "/all_leaves",
    component: Layout,
    beforeEnter: employeeRouteRule,
    children: [
      {
        path: "/all_leaves",
        component: () =>
          import("@/views/all_leaves/AllLeaves.vue"),
        name: "All Leaves",
      },
    ],
  },
  {
    path: "/my_leaves",
    component: Layout,
    beforeEnter: employeeRouteRule,
    children: [
      {
        path: "/my_leaves",
        component: () =>
          import("@/views/employee_pages/employee_leave/EmployeeLeave.vue"),
        name: "My Leaves",
      },
    ],
  },
  {
    path: "/reset_password",
    beforeEnter: employeeRouteRule,
    component: Layout,
    children: [
      {
        path: "/reset_password",
        component: () => import("@/views/reset_password/ResetPassword.vue"),
        name: "Reset Password",
      },
    ],
  },
  {
    path: "/my_leave_balances",
    beforeEnter: employeeRouteRule,
    component: Layout,
    children: [
      {
        path: "/my_leave_balances",
        component: () => import("@/views/my_leave_balance/MyLeaveBalance.vue"),
        name: "My Leave Balance",
      },
    ],
  },
  {
    path: "/resignations",
    beforeEnter: employeeRouteRule,
    component: Layout,
    children: [
      {
        path: "/resignations",
        component: () => import("@/views/resignations/Resignation.vue"),
        name: "Resignation",
      },
    ],
  },
  {
    path: "/my_attendance_report",
    beforeEnter: employeeRouteRule,
    component: Layout,
    children: [
      {
        path: "/my_attendance_report",
        component: () => import("@/views/my_attendance_report/MyAttendanceReport.vue"),
        name: "My Attendance Report",
      },
    ],
  },
  {
    path: "/leaves/:id",
    component: Layout,
    children: [
      {
        path: "/leaves/:id",
        component: () => import("@/views/leave_detail/LeaveDetail.vue"),
        name: "Leave Detail",
      },
    ],
  },

  { path: "/:pathMatch(.*)", redirect: "/404", hidden: true },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  var userTokenStorage = new UserTokenStorage();
  var isAuthenticated = userTokenStorage.read();
  if (!isAuthenticated && to.name !== "Login") {
    return { name: "Login" };
  }
});

export default router;
