<template>
  <div
    id="Sidebar"
    class="reset-menu-style"
  >
    <p
      class="flex justify-center items-center my-5"
      @click="onLogoPressed"
    >
      <span>
        <img src="@/assets/cb-logo.png">
      </span>
    </p>
    <el-menu
      class="el-menu-vertical"
      :default-active="$router.currentRoute.value.fullPath"
      mode="vertical"
      background-color="#304156"
      text-color="#fff"
      active-text-color="#ffd04b"
      :collapse="isCollapse"
      :unique-opened="false"
      :collapse-transition="false"
    >
      <template v-if="$isEmployee()">
        <el-menu-item
          index="/check_in_out"
          @click="onCheckInOutPressed"
        >
          <el-icon>
            <Clock />
          </el-icon>
          <span>Check In/Out</span>
        </el-menu-item>
      </template>
      <template v-if="$isEmployee() && !$isAdmin()">
        <el-menu-item
          index="/dashboard"
          @click="onDashBoardPressed"
        >
          <el-icon>
            <grid />
          </el-icon>
          <span>Dashboard</span>
        </el-menu-item>
      </template>

      <template v-if="($isApprover() || $isSuperApprover()) && !$isAdmin()">
        <el-sub-menu index="1">
          <template #title>
            <el-icon>
              <document />
            </el-icon>
            <span>Leave</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              :index="'/leaves?status=All&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onLeavePressed('All')"
            >All</el-menu-item>
            <el-menu-item
              :index="'/leaves?status=Requesting&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onLeavePressed('Requesting')"
            >Requesting</el-menu-item>
            <el-menu-item
              :index="'/leaves?status=Approved&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onLeavePressed('Approved')"
            >Approved</el-menu-item>
            <el-menu-item
              :index="'/leaves?status=Rejected&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onLeavePressed('Rejected')"
            >Rejected</el-menu-item>
            <el-menu-item
              :index="'/leaves?status=Withdrawed&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onLeavePressed('Withdrawed')"
            >Withdrawed</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-menu-item
          index="/employees"
          @click="onEmployeePressed"
        >
          <el-icon>
            <user />
          </el-icon>
          <span>Employees</span>
        </el-menu-item>
      </template>

      <template v-if="$isAdmin()">
        <el-menu-item
          index="/dashboard"
          @click="onDashBoardPressed"
        >
          <el-icon>
            <grid />
          </el-icon>
          <span>Dashboard</span>
        </el-menu-item>
        <el-sub-menu index="2">
          <template #title>
            <el-icon>
              <document />
            </el-icon>
            <span>Leave</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              :index="'/leaves?status=All&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onLeavePressed('All')"
            >All</el-menu-item>
            <el-menu-item
              :index="'/leaves?status=Requesting&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onLeavePressed('Requesting')"
            >Requesting</el-menu-item>
            <el-menu-item
              :index="'/leaves?status=Approved&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onLeavePressed('Approved')"
            >Approved</el-menu-item>
            <el-menu-item
              :index="'/leaves?status=Rejected&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onLeavePressed('Rejected')"
            >Rejected</el-menu-item>
            <el-menu-item
              :index="'/leaves?status=Withdrawed&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onLeavePressed('Withdrawed')"
            >Withdrawed</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-menu-item
          index="/employees"
          @click="onEmployeePressed"
        >
          <el-icon>
            <user />
          </el-icon>
          <span>Employees</span>
        </el-menu-item>
        <el-menu-item
          index="/top_leave_request"
          @click="onEmployeePerformancePressed"
        >
          <el-icon>
            <user />
          </el-icon>
          <span>Top Leave Request</span>
        </el-menu-item>
        <el-menu-item
          index="/employee_resignation"
          @click="onResignationPressed"
        >
          <el-icon>
            <user />
          </el-icon>
          <span>Employee Resignation</span>
        </el-menu-item>
        <el-menu-item
          index="/employee_attandances"
          @click="onEmployeeAttendancePressed"
        >
          <el-icon>
            <user />
          </el-icon>
          <span>Employee Attendance</span>
        </el-menu-item>
        <el-menu-item
          index="/attendance_reports"
          @click="onAttendancePressed"
        >
          <el-icon>
            <document />
          </el-icon>
          <span>Attendance Report</span>
        </el-menu-item>
        <el-menu-item
          index="/org_chart"
          @click="onOrgChartPressed"
        >
          <img
            src="@/assets/org_chart.svg"
            width="20"
            class="mr-2"
          >
          <span>Org Chart</span>
        </el-menu-item>
        <el-sub-menu index="4">
          <template #title>
            <el-icon>
              <setting />
            </el-icon>
            <span>Setup</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              index="/departments"
              @click="onDepartmentPressed"
            >Department</el-menu-item>
            <el-menu-item
              index="/branches"
              @click="onBranchPressed"
            >Branch</el-menu-item>
            <el-menu-item
              index="/users"
              @click="onUserPressed"
            >User</el-menu-item>
            <el-menu-item
              index="/leave_types"
              @click="onLeaveTypePressed"
            >Leave Type</el-menu-item>
            <el-menu-item
              index="/relationships"
              @click="onRelationPressed"
            >Relationship</el-menu-item>
            <el-menu-item
              index="/nationalities"
              @click="onNationalityPressed"
            >Nationality</el-menu-item>
            <el-menu-item
              index="/schedules"
              @click="onSchedulePressed"
            >Schedule</el-menu-item>
            <!-- <el-menu-item
              index="/settings"
              @click="onSettingPressed"
            >Settings</el-menu-item> -->
          </el-menu-item-group>
        </el-sub-menu>
      </template>
      <el-divider v-if="$isAdmin() && $isEmployee() " />
      <template v-if="$isEmployee()">
        <el-sub-menu index="3">
          <template #title>
            <el-icon>
              <document />
            </el-icon>
            <span>My Leave</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              :index="'/my_leaves?status=All&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onMyLeavePressed('All')"
            >All</el-menu-item>
            <el-menu-item
              :index="'/my_leaves?status=Requesting&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onMyLeavePressed('Requesting')"
            >Requesting</el-menu-item>
            <el-menu-item
              :index="'/my_leaves?status=Approved&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onMyLeavePressed('Approved')"
            >Approved</el-menu-item>
            <el-menu-item
              :index="'/my_leaves?status=Rejected&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onMyLeavePressed('Rejected')"
            >Rejected</el-menu-item>
            <el-menu-item
              :index="'/my_leaves?status=Withdrawed&start_date=' + $router.currentRoute.value.query.start_date + '&end_date=' + $router.currentRoute.value.query.end_date"
              @click="onMyLeavePressed('Withdrawed')"
            >Withdrawed</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-menu-item
          index="/resignations"
          @click="onResignationPressed"
        >
          <el-icon>
            <List />
          </el-icon>
          <span>Resignation</span>
        </el-menu-item>
        <el-menu-item
          index="/reset_password"
          @click="onResetPassword"
        >
          <el-icon>
            <el-icon>
              <Lock />
            </el-icon>
          </el-icon>
          <span>Reset Password</span>
        </el-menu-item>
      </template>

      <el-menu-item
        index="/my_attendance_reports"
        @click="onMyAtttendanceReportPressed"
      >
        <el-icon>
          <List />
        </el-icon>
        <span>My Attendance Report</span>
      </el-menu-item>

      <template v-if="$isEmployee() || $isApprover() || $isSuperApprover()">
        <el-menu-item
          index="/employee_leave_balance"
          @click="onLeaveBalancePressed"
        >
          <el-icon>
            <List />
          </el-icon>
          <span>My Leave Balance</span>
        </el-menu-item>
      </template>

    </el-menu>
  </div>
</template>

<script>
import { Clock } from "@element-plus/icons-vue";

export default {
  name: "SideBar",
  computed: {
    isCollapse() {
      return this.$store.state.sidebar.isCollaspe;
    },
  },
  methods: {
    onLogoPressed() {
      this.$router.push({ name: "My Leaves", query: { status: status } });
    },
    onDashBoardPressed() {
      this.$router.push({ name: "Dashboard" });
    },
    onLeavePressed(status) {
      this.$router.push({ name: "Leaves", query: { status: status } });
    },
    onEmployeePressed() {
      this.$router.push({ name: "Employees", query: { status: "On-board" } });
    },
    onEmployeeResignationPressed() {
      this.$router.push({ name: "Employee Resignations" });
    },
    onEmployeeAttendancePressed() {
      this.$router.push({ name: "Employee Attendances" });
    },
    onAttendancePressed() {
      this.$router.push({ name: "Attendance Report" });
    },
    onDepartmentPressed() {
      this.$router.push({ name: "Departments" });
    },
    onBranchPressed() {
      this.$router.push({ name: "Branches" });
    },
    onLeaveTypePressed() {
      this.$router.push({ name: "LeaveTypes" });
    },
    onUserPressed() {
      this.$router.push({ name: "Users" });
    },
    onRelationPressed() {
      this.$router.push({ name: "Relationships" });
    },
    onNationalityPressed() {
      this.$router.push({ name: "Nationalities" });
    },
    onSchedulePressed() {
      this.$router.push({ name: "Schedules" });
    },
    onMyLeavePressed(status) {
      this.$router.push({ name: "My Leaves", query: { status: status } });
    },
    onResetPassword() {
      this.$router.push({ name: "Reset Password" });
    },
    onLeaveBalancePressed() {
      this.$router.push({ name: "My Leave Balance" });
    },
    onResignationPressed() {
      this.$router.push({ name: "Resignation" });
    },
    onMyAtttendanceReportPressed() {
      this.$router.push({ name: "My Attendance Report" });
    },
    onCheckInOutPressed() {
      this.$router.push({ name: "Check In/Out" });
    },
    onSettingPressed() {
      this.$router.push({ name: "Setting" });
    },
    onEmployeePerformancePressed() {
      this.$router.push({ name: "TopLeaveRequest" });
    },
    onOrgChartPressed() {
      this.$router.push({ name: "OrgChart" });
    },
  },
};
</script>

<style lang="scss">
.el-menu {
  border: none !important;
}
.logo {
  color: white;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
</style>
